.instagram-feed-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  
  .instagram-feed-item {
    box-sizing: border-box;
    flex-basis: calc(100% / 3);
  }
  
  .instagram-feed-item img,
  .instagram-feed-item video {
    display: block;
    width: 100%;
    height: auto;
    object-fit: cover;
  }
  
  .instagram-feed-item p {
    margin-top: 10px;
    font-size: 14px;
    line-height: 1.5;
    color: #555;
  }
  