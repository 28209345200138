html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed, 
figure, figcaption, footer, header, hgroup, 
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
	margin: 0;
	padding: 0;
	border: 0;
	/* font-size: 100%; */
	font: inherit;
	vertical-align: baseline;
}

/* Mobile styles */
@media only screen and (max-width: 600px) {
	body {
			font-size: 16px;
	}
}

/* Tablet and desktop styles */
@media only screen and (min-width: 601px) {
	body {
			font-size: 18px;
	}
}

body {
  background-image: linear-gradient(140deg, rgba(174, 176, 128, 0.4), rgba(119, 89, 133, 0.4), rgba(119, 89, 133, 0.4));
  margin: 0;
	background-attachment: fixed;
}